import React from "react";
import { transformVar } from "@quarkly/atomize";
import { createGlobalStyle } from "styled-components";

const pageStyles = {
    "404": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "index": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "fonts-tester": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "#F3F4F4",
        "overflow-x": "hidden"
    },
    "uap": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/1": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/2": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/3": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "uap/4": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "uap/5": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/6": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "uap/7": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/8": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/9": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "uap/10": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/11": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "uap/12": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/13": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/14": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/15": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/16": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "uap/17": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/18": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/19": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/20": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/21": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    },
    "uap/22": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "uap/23": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--darkBodyBg",
        "overflow-x": "hidden"
    },
    "uap/analyzing-the-answers": {
        "width": "100%",
        "height": "100%",
        "font": "normal 400 16px/24px 'Roboto','Arial',sans-serif",
        "min-height": "100vh",
        "min-width": "",
        "background": "--lightBodyBg",
        "overflow-x": "hidden"
    }
};

const PageStyles = createGlobalStyle`
    body {
        ${({ styles }) =>
            Object.entries(styles || {}).map(
                ([prop, value]) => `${prop}: ${transformVar(prop, value)};`
            )}
    }
`;
export const GlobalQuarklyPageStyles = ({ pageUrl }) => <PageStyles styles={pageStyles[pageUrl]} />
